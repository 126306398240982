import React from 'react'
import { Main } from '../Facilities/Facilities.elements'
import Component from './Component'
import { Sub } from './Newsevent.element'
function Newsevent() {
    return (
        <Main>
            <Sub>
           
            </Sub>
        </Main>
    )
}

export default Newsevent
