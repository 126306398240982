import styled from "styled-components";

export const Main = styled.div`
  padding:40px;
  margin:auto;
`;
export const Sub = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);

 
`;
