import React from 'react'
import {Magzine,Facilities,Notice,Gallery,Slider} from '../../Component'

function Home() {
    return (
   <>
        <Slider/>
        {/* <Magzine/> */}
        <Facilities/>
         <Notice/>
          <Gallery/>
   </>

    )
}

export default Home
