import React from 'react'
import { Cocurriculum } from '../../Component'

function Corriculums() {
    return (
        <Cocurriculum/>
            
    )
}

export default Corriculums
