import styled from 'styled-components';

export const Main = styled.div`

`

export const Directorbackground = styled.div`
background-image: url('https://images.pexels.com/photos/450055/pexels-photo-450055.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
height:80vh;
background-repeat:no-repeat;
background-size:cover;
background-position:center;
display:flex;
flex-direction:column;
`

export const Sub = styled.div`
padding:50px;
`