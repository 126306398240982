import styled from 'styled-components';

export const Main1 = styled.div`

`

export const Directorbackground = styled.div`
background-image: url('https://images.unsplash.com/photo-1596524430615-b46475ddff6e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80');
height:50vh;
background-repeat:no-repeat;
background-size:cover;
background-position:center;
display:flex;
flex-direction:column;
`

export const Sub1 = styled.div`
padding:50px;
`